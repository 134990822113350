import React, { FC, useState } from 'react';
import styled from 'styled-components';

import Header from 'components/Header';
import { Question } from 'types/quiz';
import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import Overlay from './Overlay';
import { useRouter } from 'apis/history';
import LCFooter from 'components/LCFooter';

interface LandingLayoutProps {
  question: Question;
}

const MainContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;
  align-items: center;

  @media ${mobile} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  column-gap: 3rem;
  align-items: center;

  @media ${mobile} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 71.25rem;
  margin: 0 auto;
  padding: 3rem 1rem;
  display: flex;
  align-items: center;

  @media ${mobile} {
    padding: 2rem 1rem;

    flex-direction: column;
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
`;

const LandingLayout: FC<LandingLayoutProps> = ({ question, children }) => {
  const [show, setShow] = useState<boolean>(false);
  const { isMobile } = useQuery();
  const quiz = new URLSearchParams(location.search).get('lp') ?? 'main';

  const { goToContact, goToCases, goToStory } = useRouter();

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        <StyledContainer onClick={() => goToCases()}>
          <Text type="body500">Use cases</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStory()}>
          <Text type="body500">Our Story</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToContact()}>
          <Text type="body500">Contact Us</Text>
        </StyledContainer>
        {/* {question?.navLinks?.map((navLink, index) => (
          <a key={index} href={navLink?.url}>
            <Text type="body500">{navLink?.title}</Text>
          </a>
        ))} */}
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isMobile && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );

  return (
    <>
      <MainContainer className={show ? 'active-menu' : ''}>
        {quiz === 'main' ? (
          <>
            <div>
              <Header
                logoVariant="left"
                sticky={false}
                // RightComponent={renderRightComponent()}
                color="lightGreen"
              />
              <ContentContainer>{children}</ContentContainer>
            </div>
            <LCFooter
              disclaimerText={['© 2024 Effecto. All rights reserved.']}
            />
          </>
        ) : (
          <>
            <div>
              <Header
                logoVariant="center"
                sticky={false}
                // RightComponent={renderRightComponent()}
                color="lightGreen"
              />
              <ContentContainer>{children}</ContentContainer>
            </div>
          </>
        )}
      </MainContainer>
      {show && (
        <Overlay
          navLinks={question?.navLinks ?? []}
          socialLinks={question?.socialLinks ?? []}
        />
      )}
    </>
  );
};

export default LandingLayout;
